import { createRouter, createWebHistory } from 'vue-router';
import logor from "../loginA.vue";
import rega from "../registerA.vue";
import axios from "axios";
// import store from "../store"

const routes = [
  { 
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import("../resetPassword.vue"),
    meta: {
      requireNothing: true,
      title: 'Reset Password'
    },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import("../views/notFound.vue"),
    meta: {
      title: 'Not Found'
    }
  },
  {
    path: '/:catchAll(.*)*',
    redirect: '/404', // Redirect unmatched routes to the 404 page
  },
  {
    path: "/login",
    name: "login",
    component: logor,
    meta: {
      title: 'Login',
      requireNothing: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: rega,
    meta: {
      title: 'Register',
      requireNothing: true,
    },
  },
  {
    path: "/verify/:token",
    name: "verified",
    component: () => import(/* webpackChunkName: "about" */ "../views/verifiedPage.vue"),
    meta: {
      title: 'Verifying..',
    }
  },
  {
    path: "/",
    name: "welcome",
    component: () => import(/* webpackChunkName: "about" */ "../views/homePage.vue"),
    meta: {
      description: ""
    },    
    children: [
      {
        path: "family-tree",
        name: "family-tree",
        component: () => import(/* webpackChunkName: "about" */ "../views/aboutMe.vue"),
        meta: {
          requiresAuth: true,
          title: 'Family Tree',
        },
      },
      {
        path: "articles",
        name: "articles",
        component: () => import(/* webpackChunkName: "about" */ "../views/articlesView.vue"),
        meta: {
          title: 'Articles'
        }
      },
      {
        path: "profil",
        name: "profil",
        component: () => import(/* webpackChunkName: "about" */ "../views/setUp.vue"),
        props: (route) => ({ email: route.query.email }),
        meta: {
          title: 'Profile',
          requiresAuth: true,
        },
      },
      {
        path: "/edit",
        name: "edit",
        component: () => import(/* webpackChunkName: "about" */ "../views/editProfile.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/article/:slug",
        name: "reading",
        component: () => import(/* webpackChunkName: "about" */ "../views/readingPage.vue"),
        meta: {
          title: "Default Title",
        }
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Convert the entire route path to lowercase
  const lowercasePath = to.path.toLowerCase();

  // Check if the path is already lowercase
  if (to.path !== lowercasePath) {
    // Redirect to the lowercase version
    return next({ path: lowercasePath });
  }

  // Check if the route has a slug parameter
  if (to.params.slug) {
    try {
      // Perform an Axios request to check if the article exists
      await axios.get(`https://andrewoethara.my.id/api/article/${encodeURIComponent(to.params.slug)}`);
      // The article exists, continue to the route
      return next();
    } catch (error) {
      // The article does not exist, redirect to the 404 page
      return next({ name: 'NotFound' });
    }
  }

  // Continue to the next route
  next();
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requireNothing = to.matched.some((x) => x.meta.requireNothing);

  const isAuthenticated = JSON.parse(localStorage.getItem("token"));
  console.log(router);

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (requireNothing && isAuthenticated) {
    next({ name: "profil" || "edit" || "family-tree"});
  } else {
    next();
  }
});

export default router;