<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>

  
  @import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

  @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap');

  #app {
    font-family: "Rubik", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;

    padding: 0px !important;
    overflow: hidden;
    height: 100%;
    background-color: none;
  }
</style>
