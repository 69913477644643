<template>
  <v-app>
    <div>
      <v-img style="z-index: 0; position: absolute; filter: brightness(50%); width: 1000vh; height: 100vh" src="@/assets/airforce.jpg" cover ></v-img>

    </div>
    <v-main width="100%" class="d-flex flex-column align-center justify-center mx-auto">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card tile elevation="0" class="d-flex flex-column align-center justify-center" height="570px" width="350px">
            <div style="width: 100%;">
              <transition name="fade" mode="out-in">
                <v-alert width="70%" v-if="errorDaftar" class="mx-auto custom-chip" color="#F45050" dense>
                  <span style="color: white; font-size: 10px; font-weight: 500;">{{ errorDaftar }}</span>
                </v-alert>
              </transition>

              <transition name="fade" mode="out-in">
                <v-alert width="70%" v-if="successDaftar" class="mx-auto custom-chip" color="#1AACAC" dense>
                  <span style="color: white; font-size: 10px; font-weight: 500;">{{ successDaftar }}</span>
                </v-alert>
              </transition>

              <div fluid class="pt-12">
                <v-img v-if="!isLoading" class="mx-10" src="@/assets/01a8517e9e63b0d6cec7825435a681a0.png" min-height="10px" height="100%" max-width="100px" width="fill" contain @click="navigateToNewPage"></v-img>
              </div>

              <v-form v-if="!isLoading" v-model="buttonDaftar" ref="formDataDiri" class="mx-6" @submit.prevent="submitForm">
                <v-card-text class="py-0 mx-auto mt-8">
                  <div class="mb-6">
                    <h3 style="font-size: 20px; font-weight: 700;">Register</h3>
                    <h3 style="font-size: 12px; font-weight: 300;">Join Us.</h3>
                  </div>

                  <div>
                    <h4 class="custom-word" style="font-size: 10px !important; font-weight: 500; color: #8e8e8e;">Name</h4>

                    <input class="custom-input" id="name" name="name" v-model="name" placeholder="Your Name (Ex: John Doe or John)" :rules="nameRules" required color="#4682A9"/>
                  </div>
                  <div class="mt-2">
                    <h4 class="custom-word" style="font-size: 10px !important; font-weight: 500; color: #8e8e8e;">Email</h4>

                    <input class="custom-input" type="email" id="email" name="email" v-model="email" placeholder="Your Email" :rules="emailRules" required color="#4682A9"/>
                  </div>

                  <div class="mt-2">
                    <h4 class="custom-word" style="font-size: 10px !important; font-weight: 500; color: #8e8e8e;">Password</h4>

                    <input class="custom-input" single-line outlined id="password" name="password" v-model="password" placeholder="Your Password" :rules="passwordRules" required color="#4682A9" type="password"/>
                  </div>

                  <v-btn
                    style="padding: 6px 6px; background-color: #394867; color: white; border-radius: 6px !important; font-weight: 500; font-size: 12px;"
                    rounded
                    elevation="0"
                    class="mt-9"
                    block
                    color="#4682A9"
                    type="submit"
                    :loading="loadingButton"
                    :disabled="!buttonDaftar"
                  >
                    <span style="color: white; font-size: 8.5px; font-weight: 700;">Create</span>
                    <template v-slot:loader>
                      <v-progress-circular :width="4" :size="15" color="#91C8E4" indeterminate style="margin: 1rem;"></v-progress-circular>
                    </template>
                  </v-btn>

                  <v-btn style="padding: 6px 6px; background-color: #4682a9; color: white; border-radius: 6px !important; font-weight: 500; font-size: 12px;" rounded elevation="0" class="my-4" block color="#394867" to="/login">
                    <span style="color: white; font-size: 8.5px; font-weight: 700;">Sign In</span>
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-text>
              </v-form>

              <div v-else class="text-center py-8" height="366px">
                <v-progress-circular :width="1" :size="15" indeterminate color="#394867"></v-progress-circular>
              </div>
            </div>
          </v-card>
        </v-layout>
        <v-spacer></v-spacer>
      </v-container>
    </v-main>
  </v-app>
</template>

<script scoped>
  import axios from "axios";

  export default {
    data() {
      return {
        name: "",
        email: "",
        lastEmail: "",
        password: "",
        labelStyle: {
          "font-size": "16px", // Adjust the font size as needed
        },
        isLoading: true,
        loadingButton: false,

        formHasErrors: "",
        buttonDaftar: true,
        errorDaftar: null,
        successDaftar: null,
        nameRules: [(v) => !!v || "Name is required", (v) => /^[A-Z][a-zA-Z]*(\s[A-Z][a-zA-Z]*)*$/.test(v) || "Ex: 'John Doe' or 'John'"],
        emailRules: [(v) => !!v || "Email is required", (v) => /.+@.+\..+/.test(v) || "Email must be valid"],
        passwordRules: [(v) => !!v || "Password is required", (v) => v.length >= 8 || "Password must be at least 8 characters"],
      };
    },

    created() {


      setTimeout(() => {
        this.isLoading = false;  
      }, 1050); 

      document.title = "Register";
      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute(
          "content",
          "Join us!"
        );
      }
    },

    computed: {
      appStyle() {
        return {
          backgroundColor: "#394867",
        };
      },
    },
    methods: {
      isSubmitDisabled() {
        if (this.$refs.formDataDiri.validate()) {
          //form is valid
          this.formHasErrors = false;
        } else {
          //form is not valid
          this.formHasErrors = true;
        }
      },

      async submitForm() {
        if (this.loadingButton) {
          return;
        }
        this.errorDaftar = null;
        this.successDaftar = null;
        this.loadingButton = true;

        await new Promise((resolve) => setTimeout(resolve, 1500));

        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("password", this.password);

        try {
          const response = await axios.post("https://andrewoethara.my.id/api/register", formData, {
            withCredentials: true,

          });

          if (response.data.status === "success") {
            this.successDaftar = "Register Succeded, Check your email.";
          } else {
            this.errorDaftar = "Register Fail! Email not available.";
            console.log(response.data.message);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.loadingButton = false;
        }
      },

      navigateToNewPage() {
        // Navigate to the new page with a full page refresh
        window.location.href = "/";
      },
    },
  };
</script>

<style lang="scss">
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
</style>

<style type="text/css" scoped>


  .custom-input {
    font-size: 10px !important;
    font-weight: 400;
    width: 100%;
    padding: 5px 8px;
    border: 0.75px solid #ccc;
    border-radius: 4px;
    outline: none;
  }

  .custom-input:focus {
    border-color: #4682A9;
    border: 2px solid  #4682A9;
    margin: -1.5px -1.5px;

    font-size: 10px !important;


    /* Change the border color when the input is focused */
  }

  .custom-input::placeholder {
    color: #999; /* Style the input's placeholder text */
    font-size: 10px;

  }



  .custom-router {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0px;
    display: flex;
    justify-content: center !important;

    @media screen and (max-width: 600px) {
    }
  }

  .custom-chip {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease-in-out !important;
  }

  .custom-label-style::v-deep label {
    font-size: 10px !important;
    font-weight: 500;
    top: 7px !important;
    color: #8e8e8e;
    font-weight: 400;
  }

  .custom-label-style::v-deep fieldset {
    height: 38px !important;
    border: 0.75px solid #ccc;
    border-radius: 4px;
    padding-left: 8px;
  }

  .custom-label-style::v-deep input {
    height: 24px !important;
    padding-bottom: 0px;
    font-size: 10px;
    top: 20px !important;
    padding-top: 0px;
    margin-top: 4.5px;
  }

  .v-text-field >>> .v-input__slot {
    min-height: 18px !important;
    display: flex;
    align-items: center;
    margin: 0;
  }

  .v-text-field >>> .v-messages {
    font-size: 10px !important;
    margin-top: 7px;
    margin-bottom: 2px;
    right: 3px;
  }

  .v-text-field {
    height: 46px;
  }

  .v-text-field >>> .v-text-field__slot {
    right: 3px !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }


  input:invalid {
  background-color: #ffdddd;
  
  }

  input:valid {
        border: 0.75px solid #ccc;

  }


  input:required {
    border-width: 3px;
    border: 0.75px solid  #ccc;



  }

  input:required:invalid:label {
    border: 2px solid  #ff7363;
    margin: -1.5px -1.5px;


  }

</style>

<style lang="scss">
  ::-webkit-scrollbar {
    display: none;
  }

  /* Optional: Customize the track and thumb */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  /* Optional: Set the width and height of the thumb */
  ::-webkit-scrollbar-thumb:horizontal {
    height: 10px;
  }

  /* Optional: Set the width and height of the thumb */
  ::-webkit-scrollbar-thumb:vertical {
    width: 10px;
  }
</style>
