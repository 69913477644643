  <template class="justify-center align-center">
  <v-app>

    <div>
      <v-img style="z-index: 0; position: absolute; filter: brightness(50%); width: 1000vh; height: 100vh" src="@/assets/airforce.jpg" cover ></v-img>

    </div>

    <v-main width="100%" class="d-flex flex-column align-center justify-center mx-auto">
      <v-container fluid fill-height>

        <v-layout align-center justify-center>

          <v-card tile elevation="0" class="d-flex flex-column align-center justify-center" height="570px" width="350px">
            <div class="pb-8" style="width: 100%;">

                <v-alert max-width="70%" v-if="authMessageFail" class="my-2 custom-chip" color="#F45050" dense>
                  <span style="color: white; font-size: 10px; font-weight: 500;">{{ authMessageFail }}</span>
                </v-alert>

                <v-alert width="70%" v-if="authMessageSuccess" class="my-2 custom-chip" color="#1AACAC" dense>
                  <span style="color: white; font-size: 10px; font-weight: 500;">{{ authMessageSuccess }}</span>
                </v-alert>

              <div fluid class="pt-12">
                <v-img class="mx-10 my-8" src="@/assets/01a8517e9e63b0d6cec7825435a681a0.png" min-height="10px" height="100%" max-width="100px" width="fill" contain @click="navigateToNewPage"></v-img>
              </div>

              <v-form ref="formLogin" class="mx-6" @submit.prevent="submitLogin">

                <v-card-text class="py-0 mx-auto mt-8">
                  <div class="mb-8">
                    <h3 style="font-size: 20px; font-weight: 700;">Login</h3>
                    <h3 style="font-size: 12px; font-weight: 300;">Sharing Possibilities.</h3>
                  </div>

                  <div>
                    <h4 style="font-size: 10px !important; font-weight: 500; color: #8E8E8E;">Email</h4>

                    <input single-line outlined id="name" name="name" v-model="email" placeholder="Enter Email" required dense color="#394867" class="custom-input" style="font-size: 10px !important; font-weight: 400;"/>
                    
                  </div>
                  <div class="mt-2">
                    <h4 style="font-size: 10px !important; font-weight: 500; color: #8E8E8E;">Password</h4>

                    <input
                    single-line
                    outlined
                    id="password"
                    name="password"
                    v-model="password"
                    placeholder="Password"
                    required
                    dense
                    color="#394867"
                    type="password"
                    class="custom-input "
                    style="font-size: 12px; font-weight: 400;"
                    />

                    <router-link class="mt-1 d-flex justify-end" style="text-decoration: none; font-size: 9px; font-weight:500;" to="/resetPassword">Forget Password?</router-link>
                  </div>

                  <v-btn style="padding: 6px 6px; background-color:#4682A9; color: white; border-radius: 6px !important; font-weight: 500; font-size:12px;" rounded elevation="0" class="mt-4" block color="#4682A9" type="submit" :loading="loadingButton" :disabled="isSubmitDisabled">
                    <span style="color: white; font-size: 8.5px; font-weight: 700;">Login</span>
                    <template v-slot:loader>
                      <v-progress-circular :width="4" :size="15" color="#91C8E4" indeterminate style="margin: 1rem;"></v-progress-circular>
                    </template>
                  </v-btn>
                  <v-btn style="padding: 6px 6px; background-color:#394867; color: white; border-radius: 6px !important; font-weight: 500; font-size:12px;" rounded elevation="0" class="my-4" block color="#394867" to="/register">
                    <span style="color: white; font-size: 8.5px; font-weight: 700;">Register</span>
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-card-text>
              </v-form>
            </div>
          </v-card>
        </v-layout>
        <v-spacer></v-spacer>
      </v-container>
    </v-main>

  </v-app>
</template>

<script scoped>
  import axios from "axios";


  export default {
    data() {
      return {
        email: "",
        password: "",
        authMessageSuccess: "",

        authMessageFail: "",
        loadingButton: false,

        labelStyle: {
          "font-size": "16px", // Adjust the font size as needed
        },

        emailRules: [(v) => !!v || "Email is required", (v) => /.+@.+\..+/.test(v) || "Email must be valid"],
        passwordRules: [(v) => !!v || "Password is required", (v) => v.length >= 8 || "Password must be at least 8 characters"],
      };
    },

    created() {
      document.title = "Login";
      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute(
          "content",
          "Login and share what is in your Mind."
          );
      }
    },
    computed: {
      isSubmitDisabled() {
        return !this.email || !this.password;
      },
      appStyle() {
        return {
          backgroundColor: "#394867",
        };
      },
    },

    methods: {
      async submitLogin() {

        if (this.$refs.formLogin.validate()) {
          // Form is valid, send a POST request to authenticate
          if (this.loadingButton) {
            return;
          }
          this.authMessageSuccess = null;
          this.authMessageFail = null;
          this.loadingButton = true;

          const formData = new FormData();
          formData.append("email", this.email);
          formData.append("password", this.password);


          try {

            const loginResponse = await axios.post("https://andrewoethara.my.id/api/login", formData,

            {
              withCredentials: true,
            },
            );

            if (loginResponse.data.status === "success") {
              this.authMessageSuccess = "Login Berhasil!";
              const userEmail = this.email; // Get the user's email
              console.log("Received email:", userEmail);

              const userDataResponse = await axios.get(`https://andrewoethara.my.id/api/request?email=${userEmail}`);

              const userData = userDataResponse.data;
              this.$store.commit("setToken", loginResponse.data.token);

              this.$store.commit("setUserData", userData);
              localStorage.setItem("userData", JSON.stringify(userData));

              console.log(userData);
              console.log('Login Response:', loginResponse);


              console.log("Is authenticated:", this.$store.getters.isAuthenticated);

              // Set the user data in Vuex store

              this.$router.push({ name: "profil"});
            } else {
              this.authMessageFail = "Password Salah atau Akun tidak ditemukan";
            }
          } catch (error) {
            console.log("Error", error.message);
            this.authMessageFail = "Password Salah atau Akun tidak ditemukan";

          } finally {
            this.loadingButton = false;
          }
        }
      },

      navigateToNewPage() {
        window.location.href = "/";
      },
    },
  };
</script>

<style type="text/css" scoped>


  .video-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the alpha (fourth) value for darkness */
  }

  .custom-chip {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease-in-out !important;

  }

  .custom-label-style::v-deep label {
    font-size: 12px !important;
    font-weight: 500;
  }

  .v-text-field >>> .v-text-field__details {
    padding-top: 0;
    margin-bottom: 2px !important;
    font-size: 8.5px !important;
  }

  .custom-input {
    width: 100%;
    padding: 5px 8px;
    border: 0.75px solid #ccc;
    border-radius: 4px;
    outline: none;
  }

  .custom-input:focus {
    border-color: #4682A9;
    border: 2px solid  #4682A9;
    margin: -1.5px -1.5px;

    font-size: 8px !important;


    /* Change the border color when the input is focused */
  }

  .custom-input::placeholder {
    color: #999; /* Style the input's placeholder text */
    font-size: 10px;

  }

  @keyframes rotateBackground {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 100%;
    }
  }

  .fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

</style>

<style lang="scss">

  
  ::-webkit-scrollbar {
    display: none;
  }

  /* Optional: Customize the track and thumb */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  /* Optional: Set the width and height of the thumb */
  ::-webkit-scrollbar-thumb:horizontal {
    height: 10px;
  }

  /* Optional: Set the width and height of the thumb */
  ::-webkit-scrollbar-thumb:vertical {
    width: 10px;
  }
  
</style>