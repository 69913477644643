import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';

import { MotionPlugin } from '@vueuse/motion'


import { createMetaManager } from 'vue-meta'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons';



library.add(faArrowUpRightFromSquare);
library.add(faImages);
library.add(faPenToSquare);
library.add(faXmark);
library.add(faBars);
library.add(faLinkedinIn);
library.add(faWhatsapp);







const app = createApp(App);


app.component('font-awesome-icon', FontAwesomeIcon);

app
  .use(MotionPlugin)
  .use(router)
  .use(createMetaManager())
  .use(store)
  .use(vuetify)
  .mount('#app');
